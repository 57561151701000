import { CookieKeys, getClientCookie } from '@finn/ui-utils';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useDiscoveryConfig } from '../../core/data/MetadataProvider';

export const isValidPartnerLP = (routerPath: string) => {
  if (routerPath.includes('/partnerships/')) {
    return true;
  }
  if (routerPath.includes('/club')) {
    return true;
  }

  return false;
};

const isValidPath = (routerPath: string) => {
  if (isValidPartnerLP(routerPath)) {
    return true;
  }

  if (routerPath.includes('/checkout/')) {
    return true;
  }
  const regex = /\/pdp.*\//;
  if (regex.test(routerPath)) {
    return true;
  }

  return false;
};

export enum VoucherClass {
  influencer = 'influencer',
  strategic_partner = 'strategic_partner',
  closed_user_group = 'closed_user_group',
}

export enum VoucherType {
  RELATIVE = 'relative-value',
  ABSOLUTE = 'absolute-value',
}

export type ReferralVoucherData = {
  amount: number;
  type: string;
  code: string;
  is_valid: boolean;
  referrer: string;
  value_abs: number;
  value_rel: number;
  image: { url: string };
  class: VoucherClass;
};

const getVoucherData = (): ReferralVoucherData => {
  const referralVoucherCookieData = getClientCookie(
    CookieKeys.REFERRAL_VOUCHER_CODE
  );

  return JSON.parse(referralVoucherCookieData ?? '{}') as ReferralVoucherData;
};

export const isValidRelativePartnerVoucher = (
  voucherData: ReferralVoucherData
) => {
  return (
    voucherData?.class === VoucherClass?.closed_user_group &&
    voucherData?.is_valid &&
    voucherData?.value_rel
  );
};

export type PartialCartInfo = Partial<{
  voucherClass: VoucherClass;
  voucherType: VoucherType;
  voucherAmount: number;
  originalAmount: number;
}>;

export const getPartnerDiscountFromDeal = (
  cartInfo: PartialCartInfo
): number => {
  if (cartInfo?.voucherClass !== VoucherClass.closed_user_group) {
    return 0;
  }
  if (cartInfo?.voucherType !== VoucherType.RELATIVE) {
    return 0;
  }
  if (!cartInfo?.voucherAmount || !cartInfo?.originalAmount) {
    return 0;
  }

  return cartInfo.voucherAmount / cartInfo.originalAmount;
};

export const usePartnerDiscount = ({
  closedUserGroupId,
  hasVoucher,
  cartInfo,
}) => {
  const [partnerDiscount, setPartnerDiscount] = useState(0);
  const partnerDiscountFromDeal = getPartnerDiscountFromDeal(cartInfo);
  const pathname = usePathname();
  const { router } = useDiscoveryConfig();
  const routerPath = pathname || router?.asPath || '';
  useEffect(() => {
    if (!isValidPath(routerPath) || closedUserGroupId) {
      return;
    }
    const voucherData = getVoucherData();
    if (isValidRelativePartnerVoucher(voucherData)) {
      setPartnerDiscount(voucherData.value_rel);
    }
  }, [hasVoucher, routerPath, closedUserGroupId]);

  return partnerDiscountFromDeal || partnerDiscount;
};
