'use client';
import { Button } from '@finn/design-system/atoms/button';
import { CheckoutStep } from '@finn/ua-auth';
import { errorMessages } from '@finn/ua-constants';
import {
  checkoutErrorEvent,
  interactionTrackingEvent,
  TrackingEventName,
} from '@finn/ui-modules';
import { Locale, parseToHtml, useCurrentLocale } from '@finn/ui-utils';
import get from 'lodash/get';
import Link from 'next/link';
import React, { useEffect } from 'react';

import HeadTags from '~/components/HeadTags';
import SpacingBox from '~/components/SpacingBox';
import Page from '~/layouts/Default';
import { ErrorPageData, SeoHeaderData } from '~/types/cosmicModules';
import ProductSlider from '~/ui-modules/ProductSlider';

interface IProps {
  pageData: ErrorPageData;
  statusCode: number;
  requestId?: string;
  error: Error | null;
  checkoutStep?: CheckoutStep;
}

const ErrorPage: React.FunctionComponent<IProps> = ({
  checkoutStep,
  pageData,
  statusCode,
  error,
  requestId,
}) => {
  const { locale } = useCurrentLocale();
  const type = statusCode === 404 ? '404' : '500';
  const title = parseToHtml(
    get(pageData, 'metadata.content.headlines.title', '')
  );
  const description = parseToHtml(
    get(pageData, `metadata.headlines.description_${type}`, '')
  );

  const buttonText = parseToHtml(
    get(pageData, 'metadata.content.button.text', '')
  );
  const buttonHref = get(pageData, 'metadata.content.button.href', '');

  useEffect(() => {
    if (checkoutStep) {
      console.error(`Server side error ${error} Request ID: ${requestId}`);

      checkoutErrorEvent(
        errorMessages.PAGE_LOAD_FAILED,
        checkoutStep,
        error?.message,
        statusCode,
        requestId
      );
    } else {
      interactionTrackingEvent(TrackingEventName.Error, {});
    }
  }, []);

  const seoHeader = get(
    pageData,
    `metadata.content.seo_header_${type}`,
    {}
  ) as SeoHeaderData;

  return (
    <Page data={pageData}>
      <HeadTags seoHeader={seoHeader} isNoIndexNoFollow={true} />
      <SpacingBox>
        <div className="container">
          <div className="mb-14 sm:mb-16">
            <h1 className="mobile-t1-semibold sm:web-t1-semibold mb-2">
              {title}
            </h1>
            <p className="ml-1">{description}</p>
          </div>
        </div>
        {locale !== Locale.ENGLISH_GERMANY && (
          <ProductSlider
            title={get(pageData, 'metadata.content.vehicles_slider_title', '')}
          />
        )}
        <div className="container">
          <div className="mt-12">
            <Link href={buttonHref} legacyBehavior>
              <Button href={buttonHref}>{buttonText}</Button>
            </Link>
          </div>
        </div>
      </SpacingBox>
    </Page>
  );
};

export default ErrorPage;
