import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type VoucherState = {
  hasVoucher: boolean;
  setHasVoucher: (isReady: boolean) => void;
};

// for now just added one variable needed for voucher banner re-render, will migrate more from voucher context in the future
export const useVoucherStore = create<VoucherState>()(
  devtools(
    immer((set) => ({
      hasVoucher: false,
      setHasVoucher: (value) => {
        set((state) => {
          state.hasVoucher = value;
        });
      },
    })),
    { name: 'VoucherStore' }
  )
);
